<!-- 异常监测 tcp服务异常分页列表  -->
<template>
  <div>
    <div class="box-card" style="text-align: left">
      <!-- 主要内容 -->
      <div>
        <!-- 查询表单 -->
        <el-form :inline="true" size="small" label-position="right" class="query_form" :model="queryForm"
          @submit.native.prevent>
          <el-form-item label="停车场：">
            <el-select popper-class="my-select" clearable v-model="queryForm.parking_id" filterable default-first-option
              placeholder="请选择停车场" @change="onSubmit">
              <el-option v-for="item in parkingList" :key="item.parking_id" :label="item.parking_name"
                :value="item.parking_id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="创建时间：">
            <el-date-picker v-model="timeduan" value-format="yyyy-MM-dd HH:mm:ss" :default-time="['00:00:00', '23:59:59']"
              @blur="onSubmit" type="datetimerange" range-separator="至" start-placeholder="请选择开始日期"
              end-placeholder="请选择结束日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <lbButton icon="chaxun" :fill="true" @click="onSubmit()">查询</lbButton>
          </el-form-item>
        </el-form>
        <le-card title="消费监测记录">
          <div>
            <!-- 表格 -->
            <!-- 使用插件生产表格 -->
            <!-- <avue-crud :data="tableData" :option="option" :page.sync="page" @size-change="sizeChange"
              @current-change="currentChange">
              <template slot-scope="{row}" slot="exception_time">
                <span>{{ validDateTime(row.exception_time) }}</span>
              </template>
            </avue-crud> -->
            <el-table class="tablestyle" ref="multipleTable" :data="tableData" align="center" header-align="center"
              tooltip-effect="dark" border stripe>
              <el-table-column prop="parking_name" label="停车场" width="180"></el-table-column>
              <el-table-column prop="exception_message" label="异常信息" show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="exception_time" label="创建时间" width="180">
                <template slot-scope="scope">
                  <span>{{ validDateTime(scope.row.exception_time) }}</span>
                </template>
              </el-table-column>
            </el-table>
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="queryForm.PageIndex" :page-sizes="[10, 20, 30, 40]" :page-size="queryForm.PageSize"
              layout="total, sizes, prev, pager, next, jumper" :total="TotalCount">
            </el-pagination>
          </div>
        </le-card>
      </div>
    </div>
  </div>
</template>

<script>
import { getTcpExceptionPageList } from '@/api/maintenanceCenter.js'
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
export default {
  data () {
    return {
      // 查询表单
      queryForm: {
        tcpservice_device_no: '', // tcp服务序列号
        parking_id: '',
        parking_name: null,
        end_time: '',
        start_time: '', // 创建开始时间
        PageSize: 10,
        PageIndex: 1
      },
      timeduan: '',
      // 表格数据
      tableData: [],
      TotalCount: 0,
      detailInfo: {},
      deviceList: [],
      // 表格插件配置项
      option: {
        searchMenuSpan: 8,
        header: false,
        delBtn: false, // 行删除按钮
        editBtn: false, // 编辑按钮
        menuAlign: 'left', // 属性对齐方式
        menuHeaderAlign: 'left', // 表头对齐方式
        menu: false,
        menuFixed: false, // 解除操作框冻结
        tip: false,
        column: [
          {
            label: '停车场',
            prop: 'parking_name'
          }, {
            label: '异常信息',
            prop: 'exception_message',
            width: 1200,
            overHidden: true
          }, {
            label: '创建时间',
            prop: 'exception_time',
            slot: true
          }
        ]
      },
      // 分页器
      page: {
        background: false,
        total: 0,
        pageSize: 10,
        currentPage: 1
      }
    }
  },
  computed: {
    ...mapState(['parkingList'])
  },
  created () { },
  watch: {
    'timeduan' (newV, oldV) {
      if (newV) {
        this.queryForm.start_time = newV[0]
        this.queryForm.end_time = newV[1]
      } else {
        this.queryForm.start_time = ''
        this.queryForm.end_time = ''
        this.onSubmit()
      }
    }
  },
  mounted () {
    this.fngetTcpExceptionPageList()
  },
  methods: {
    // 获取异常监测数据
    async fngetTcpExceptionPageList () {
      const res = await getTcpExceptionPageList({
        tcpservice_device_no: this.queryForm.tcpservice_device_no,
        parking_id: this.queryForm.parking_id,
        parking_name: this.queryForm.parking_name,
        end_time: this.queryForm.end_time,
        start_time: this.queryForm.start_time, // 创建开始时间
        PageSize: this.queryForm.PageSize,
        PageIndex: this.queryForm.PageIndex
      })
      console.log('获取数据', res)
      this.tableData =
        res && res.Code === 200 && res.Data ? res.Data.DataList : []
      this.TotalCount =
        res && res.Code === 200 && res.Data ? res.Data.TotalCount : 0
      this.page.total = res && res.Code === 200 && res.Data ? res.Data.TotalCount : 0
    },
    // 查询
    onSubmit () {
      this.queryForm.PageIndex = 1
      this.fngetTcpExceptionPageList()
    },
    // 每页条数改变
    handleSizeChange (size) {
      this.queryForm.PageSize = size
      this.fngetTcpExceptionPageList()
    },
    // // 每页条数改变
    // sizeChange (val) {
    //   this.page.currentPage = 1
    //   this.page.pageSize = val
    //   this.queryForm.PageSize = val
    //   this.fngetTcpExceptionPageList()
    // },
    // 页码改变
    handleCurrentChange (page) {
      this.queryForm.PageIndex = page
      this.fngetTcpExceptionPageList()
      this.$refs.multipleTable.bodyWrapper.scrollTop = 0
    }
    // // 页码改变
    // currentChange (val) {
    //   this.queryForm.PageIndex = val
    //   this.page.currentPage = val
    //   this.fngetTcpExceptionPageList()
    // }
  }
}
</script>

<style scoped lang="less">
.box-card {
  margin-top: 20px;
}

.button_box {
  display: flex;
}

.button_box .el-button {
  margin-left: 15px;
  width: auto;
}

.el-dialog__body {
  padding: 20px;
}

.el-dialog {
  /deep/ .el-dialog--center.el-dialog__footer {
    text-align: right;
  }
}

// .el-button {
//   width: auto !important;
// }

.dialog_box {
  .title_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    font-size: 16px;
    color: #000;
  }

  /deep/ .detail_table {
    margin-bottom: 0;

    .el-table__body-wrapper {
      max-height: 250px;
      overflow-y: auto;
    }
  }
}
</style>
